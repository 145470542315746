import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setWarningText } from '../redux/actions'

function Warning(props) {
  const [text, setText] = useState(null)

  useEffect(() => {
    if (props.gameAction.warningText === null) return

    setText(props.gameAction.warningText)
  }, [props.gameAction.warningText])

  const onClick = () => {
    props.setWarningText(null)
    setText(null)
  }

  if (text === null) return null

  return (
    <div id="notenoughpoints" onClick={onClick}>
      <div className="nep-row">
        <div className="nep-col">
          <div className="nep-content">{text}</div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setWarningText: (payload) => dispatch(setWarningText(payload)),
  }
}

const mapStateToProps = (state) => {
  const { gameAction } = state
  return { gameAction }
}

export default connect(mapStateToProps, mapDispatchToProps)(Warning)
