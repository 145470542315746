// -------------------------------------------------------------------------------------- game-action action

export const showBigNews = (payload) => {
  return {
    type: 'SHOW_BIG_NEWS',
    payload: payload,
  }
}

export const setWarningText = (payload) => {
  return {
    type: 'SET_WARNING_TEXT',
    payload: payload,
  }
}

export const setBoughtHints = (payload) => {
  return {
    type: 'SET_BOUGHT_HINTS',
    payload: payload,
  }
}

export const setLoading = (payload) => {
  return {
    type: 'SET_LOADING',
    payload: payload,
  }
}

export const setActions = (payload) => {
  return {
    type: 'SET_ACTIONS',
    payload: payload,
  }
}

export const setSelectedActions = (payload) => {
  return {
    type: 'SET_SELECTED_ACTIONS',
    payload: payload,
  }
}

export const setActionClosed = (payload) => {
  return {
    type: 'SET_ACTION_STATE',
    payload: 'ACTION_CLOSED',
  }
}

export const setActionOpened = (payload) => {
  return {
    type: 'SET_ACTION_STATE',
    payload: 'ACTION_OPENED',
  }
}

export const setFeedbackOpened = (payload) => {
  return {
    type: 'SET_ACTION_STATE',
    payload: 'FEEDBACK_OPENED',
  }
}

export const setFeedbackData = (payload) => {
  return {
    type: 'SET_FEEDBACK_DATA',
    payload: payload,
  }
}

export const resetGameAction = (payload) => {
  return {
    type: 'RESET_GAME_ACTION',
    payload: payload,
  }
}

export const setEndScreen = (payload) => {
  return {
    type: 'SET_END_SCREEN',
    payload: payload,
  }
}

// -------------------------------------------------------------------------------------- game progress action

export const setGameId = (payload) => {
  return {
    type: 'SET_GAME_ID',
    payload: payload,
  }
}

export const startLoop = (payload) => {
  return {
    type: 'START_LOOP',
    payload: payload,
  }
}

export const setLoop = (loop) => {
  return {
    type: 'SET_LOOP',
    payload: loop,
  }
}

export const setInsightPoint = (point) => {
  return {
    type: 'SET_INSIGHT_POINT',
    payload: point,
  }
}

export const setGamePoint = (point) => {
  return {
    type: 'SET_GAME_POINT',
    payload: point,
  }
}

export const resetGameProgress = (point) => {
  return {
    type: 'RESET_GAME_PROGRESS',
    payload: point,
  }
}

export const removeSmallNews = (payload) => {
  return {
    type: 'REMOVE_SMALL_NEWS',
    payload: payload,
  }
}

export const removeBigNews = (payload) => {
  return {
    type: 'REMOVE_BIG_NEWS',
    payload: payload,
  }
}

export const setIsActionEnd = (payload) => {
  return {
    type: 'SET_IS_ACTION_END',
    payload: payload,
  }
}

export const setEndPage = (gameData) => {
  return {
    type: 'SET_END_PAGE',
    payload: gameData,
  }
}

export const setInsightChoice = (payload) => {
  return {
    type: 'SET_INSIGHT_CHOICE',
    payload: payload,
  }
}

export const setTotalInsightPoint = (payload) => {
  return {
    type: 'SET_TOTAL_INSIGHT_POINT',
    payload: payload,
  }
}

export const startGame = (payload) => {
  return {
    type: 'START_GAME',
    payload: payload,
  }
}

export const setHotspotCoordinate = (payload) => {
  return {
    type: 'SET_HOTSPOT_COORDINATE',
    payload: payload,
  }
}

export const setResult = (payload) => {
  return {
    type: 'SET_RESULT',
    payload: payload,
  }
}

// -------------------------------------------------------------------------------------- game-data action

export const setLastUpdate = (lastUpdate) => {
  return {
    type: 'SET_LAST_UPDATE',
    payload: lastUpdate,
  }
}

export const setGameData = (gameData) => {
  return {
    type: 'SET_GAME_DATA',
    payload: gameData,
  }
}

// -------------------------------------------------------------------------------------- tutorial action

export const resetTutorial = (payload) => {
  return {
    type: 'RESET_TUTORIAL',
    payload: payload,
  }
}

export const addSeenTutorial = (payload) => {
  return {
    type: 'ADD_SEEN_TUTORIAL',
    payload: payload,
  }
}

export const showSmallNewsTutorial = (payload) => {
  return {
    type: 'SHOW_SMALL_NEWS_TUTORIAL',
    payload: payload,
  }
}

export const showBigNewsTutorial = (payload) => {
  return {
    type: 'SHOW_BIG_NEWS_TUTORIAL',
    payload: payload,
  }
}

export const showInsightActionOptionTutorial = (payload) => {
  return {
    type: 'SHOW_INSIGHT_ACTION_OPTION_TUTORIAL',
    payload: payload,
  }
}

export const showInsightActionSubmitTutorial = (payload) => {
  return {
    type: 'SHOW_INSIGHT_ACTION_SUBMIT_TUTORIAL',
    payload: payload,
  }
}

export const showFeedbackTutorial = (payload) => {
  return {
    type: 'SHOW_FEEDBACK_TUTORIAL',
    payload: payload,
  }
}

export const showEndActionOptionTutorial = (payload) => {
  return {
    type: 'SHOW_END_ACTION_OPTION_TUTORIAL',
    payload: payload,
  }
}

export const showHintButtonTutorial = (payload) => {
  return {
    type: 'SHOW_HINT_BUTTON_TUTORIAL',
    payload: payload,
  }
}

export const showAfterShowHintTutorial = (payload) => {
  return {
    type: 'SHOW_AFTER_SHOW_HINT_TUTORIAL',
    payload: payload,
  }
}

export const showEndActionSubmitTutorial = (payload) => {
  return {
    type: 'SHOW_END_ACTION_SUBMIT_TUTORIAL',
    payload: payload,
  }
}

export const showTutorial = (payload) => {
  return {
    type: 'SHOW_FIRST_TUTORIAL',
    payload: payload,
  }
}

export const showInsightPointsTutorial = (payload) => {
  return {
    type: 'SHOW_INSIGHT_POINTS_TUTORIAL',
    payload: payload,
  }
}

export const showTimerTutorial = (payload) => {
  return {
    type: 'SHOW_TIMER_TUTORIAL',
    payload: payload,
  }
}

export const showInsightActionButtonTutorial = (payload) => {
  return {
    type: 'SHOW_INSIGHT_ACTION_BUTTON_TUTORIAL',
    payload: payload,
  }
}
