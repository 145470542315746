import { useEffect, useState } from 'react';

function InstallStandaloneIOS() {
	const [open, setOpen] = useState(true);

	if (!open) return null;
	return (
		<div id='installHome'>
			Installeer deze game op je telefoon; Klik op{' '}
			<img src='/assets/images/shareIos.png' alt='Share Icon' /> en kies "Zet op
			beginscherm"
			<a onClick={() => setOpen(false)} className='closeInstall' title='Close'>
				Sluiten
			</a>
		</div>
	);
}

export default InstallStandaloneIOS;
