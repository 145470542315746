import { useEffect, useState } from 'react'
import { setActionClosed, setActionOpened, setFeedbackOpened, showFeedbackTutorial } from '../redux/actions'
import { connect } from 'react-redux'

function Feedback(props) {
  const [openFeedback, setOpenFeedback] = useState(false)

  useEffect(() => {
    if (props.gameAction.actionState === 'FEEDBACK_OPENED') {
      setTimeout(() => {
        setOpenFeedback(true)
      }, 10)

      setTimeout(() => {
        if (props.gameAction.feedback.referer === 'INSIGHT_ACTION') {
          props.showFeedbackTutorial()
        }
      }, 700)
    } else {
      setOpenFeedback(false)
    }
  }, [props.gameAction.actionState])

  return (
    <div id="feedback" className={props.gameAction.feedback.status + ' ' + (openFeedback && 'opened')}>
      <span className="grid-frame grid-frame-top"></span>
      <div className="feedback-inner" onClick={props.gameAction.feedback.onClose}>
        <div className="feedback-content">
          <header className="feedback-header">
            <span className="fh-icon"></span>
            <h4>
              {props.gameAction.feedback.referer === 'INSIGHT_ACTION' &&
                props.gameAction.feedback.point + ' PUNTEN'}
            </h4>
          </header>
          <p dangerouslySetInnerHTML={{ __html: props.gameAction.feedback.text }}></p>
        </div>
        <button id="next" className="btn positive-btn" onClick={props.gameAction.feedback.onClose}>
          Volgende
        </button>
      </div>
      <span className="grid-frame grid-frame-bottom"></span>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActionClosed: (payload) => dispatch(setActionClosed(payload)),
    showFeedbackTutorial: (payload) => dispatch(showFeedbackTutorial(payload)),
  }
}

const mapStateToProps = (state) => {
  const { gameAction } = state
  return { gameAction }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
