import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import { startGame, setLoading } from '../redux/actions'
import WPMedia from './WPMedia'
import helper from '../lib/helper'

function OnboardingSlide(props) {
  return (
    <div className="obcontent" key={props.key}>
      {props.onboarding.title && <h2 className="obTitle">{props.onboarding.title}</h2>}
      {props.onboarding.image && <img src={props.onboarding.image.url} />}
      {props.onboarding.content && (
        <div className="entrycontent" dangerouslySetInnerHTML={{ __html: props.onboarding.content }}></div>
      )}
    </div>
  )
}

function Onboarding(props) {
  const [isDisplayVideo, setIsDisplayVideo] = useState(false)
  const [allowStartGame, setAllowStartGame] = useState(false)
  const [refSliderOb, setRefSliderOb] = useState(null)
  const [refSliderObDots, setRefSliderObDots] = useState(null)
  const [understoodButtonEnabled, setUnderstoodButtonEnabled] = useState(false)
  /// props.startGame() // TESTTEST

  const startGame = () => {
    props.setLoading(true)
    setTimeout(() => {
      props.startGame()
      helper.stopAllMedia()
      props.setLoading(false)
    }, 250)
  }

  const onGoToIntroVideo = () => {
    props.setLoading(true)
    setTimeout(() => {
      props.setLoading(false)
      setIsDisplayVideo(true)
    }, 250)
  }

  useEffect(() => {
    if (isDisplayVideo) {
      setTimeout(() => setAllowStartGame(true), 1000)
    }
  }, [isDisplayVideo])

  var onboardings
  try {
    onboardings = props.gameData.data.config.onboardings || []
  } catch (e) {
    onboardings = []
    return null
  }

  const sliderObSetting = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 300,
    slidesToShow: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
    beforeChange: (current, next) => {
      const seenAllSliders = next == onboardings.length - 1
      if (seenAllSliders) {
        setUnderstoodButtonEnabled(true)
      }

      if (!refSliderObDots) return
      setTimeout(() => refSliderObDots.slickGoTo(next), 100)
      setTimeout(() => refSliderObDots.slickGoTo(next), 500)
    },
    asNavFor: refSliderObDots,
  }

  const sliderObDotsSetting = {
    slidesToShow: onboardings.length + 2,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    infinite: false,
    centerMode: true,
    focusOnSelect: false,
    swipe: false,
  }

  if (isDisplayVideo) {
    return (
      <div id="onboarding" className="videoIntro">
        <div className="ob-row">
          <div className="ob-col">
            {props.gameData.data.config.onboardingVideo && (
              <div>
                <button
                  type="button"
                  className="btn btn-primary disabled closeButton"
                  disabled={!allowStartGame}
                  onClick={startGame}
                >
                  X
                </button>
                <WPMedia media={props.gameData.data.config.onboardingVideo} />
              </div>
            )}
            {!props.gameData.data.config.onboardingVideo && <h1>Onboarding video not found</h1>}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id="onboarding">
      <div className="ob-row">
        <div className="ob-col">
          <Slider className="ob-slides" {...sliderObSetting} ref={(sliderOb) => setRefSliderOb(sliderOb)}>
            {onboardings.map((onboarding, index) => (
              <OnboardingSlide key={index} onboarding={onboarding} />
            ))}
          </Slider>
        </div>
      </div>

      <Slider
        className="obSlidesDots"
        {...sliderObDotsSetting}
        ref={(sliderOb) => setRefSliderObDots(sliderOb)}
      >
        {onboardings.map((onboarding, index) => (
          <span key={index} className="slideDot">
            {onboarding.id}
          </span>
        ))}
      </Slider>

      <button
        type="button"
        id="finishOnboarding"
        title="Begrepen"
        className="btn btn-primary disabled"
        disabled={!understoodButtonEnabled}
        onClick={onGoToIntroVideo}
      >
        Begrepen
      </button>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    startGame: (payload) => dispatch(startGame(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
  }
}

const mapStateToProps = (state) => {
  const { gameData, gameProgress } = state
  return { gameData, gameProgress }
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding)
