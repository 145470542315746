import config from '../config'

class Request {
  constructor() {
    this.endpoint = config[config.mode].endpoint
    this.noCache = false
  }

  async getGameData() {
    return this.get('/wp-json/v1/defence/game-data')
  }

  async postAnalytic(gameId, startOrFinish = 'start') {
    // startOrFinish = 'start'|'finish'
    const data = 'game_id=' + gameId.toString() + '&start_or_finish=' + startOrFinish
    return this.get('/wp-json/v1/defence/analytic?' + data)
  }

  async getLastUpdate() {
    return this.get('/wp-json/v1/defence/last-update')
  }

  async get(path) {
    const noCacheAddition = this.noCache ? '?time=' + +new Date() : ''
    const res = await fetch(this.endpoint + path + noCacheAddition)
    const ok = res.ok
    const body = await res.json()
    return { ok, body }
  }

  async post(path, data) {
    return
  }
}

const request = new Request()

export default request
