import { useEffect, useState } from 'react'
import { setLoading, setEndPage, setGamePoint, setEndScreen } from '../redux/actions'
import { connect } from 'react-redux'

import WPMedia from '../components/WPMedia'
import helper from '../lib/helper'

function EndScreenFeedback(props) {
  const [animation, setAnimation] = useState(null)
  const [endFeedback, setEndFeedback] = useState(false)
  const [insight, setInsight] = useState(false)
  const [debriefing, setDebriefing] = useState(false)

  const feedbackText = {
    positive: 'Really Good',
    neutral: 'That was okay',
    negative: 'That was so bad',
  }

  const goToInsight = () => {
    setAnimation(null)
    setInsight(true)
    setEndFeedback(false)
    setDebriefing(false)
  }

  const goToDebriefing = () => {
    setAnimation(null)
    setDebriefing(true)
    setEndFeedback(false)
    setInsight(false)
  }

  const totalInsightHandler = () => {
    let choices = props.gameProgress.insightChoice.map((insight) => {
      const actionInsight = props.gameData.data.actionInsights.find((actInsight) => actInsight.id === insight)
      return actionInsight
    })
    return choices
  }

  const buttonColorHandler = () => {
    if (props.gameProgress.endPage.status === 'neutral') {
      return 'btn btn-primary'
    } else if (props.gameProgress.endPage.status === 'negative') {
      return 'btn negative-btn'
    } else {
      return 'btn positive-btn'
    }
  }

  const contentColorHandler = () => {
    if (props.gameProgress.endPage.status === 'neutral') {
      return 'end-screen-content neutral'
    } else if (props.gameProgress.endPage.status === 'negative') {
      return 'end-screen-content negative'
    } else {
      return 'end-screen-content positive'
    }
  }

	const getMaxPoints = (round) => {
		const actionInsightCurrRound = props.gameData.data.actionInsights.filter(v => v.loop == round)
		const actionInsightPositive = actionInsightCurrRound.filter(v => v.points > 0)
		const actionInsightPoints = actionInsightPositive.map(v => v.points)
		const max = actionInsightPoints.reduce((a, b) => a + b, 0)
		return max
	}

  useEffect(() => {
    setTimeout(() => setAnimation('active'), 300)
    setAnimation(null)
  }, [insight, debriefing, endFeedback])

  const onGoToHomepage = () => {
    helper.stopAllMedia()
    props.goToHomePage()
  }

  if (!props.gameProgress.endPage) return (window.location.href = '/')

  if (!insight && !debriefing && !endFeedback) {
    return (
      <div id="end-screen">
        <div id="end-screen-container" className={animation}>
          <header id="endscreenHeader">
            <h5>Resultaat</h5>
          </header>
          <div className={contentColorHandler()}>
            {animation && (
              <div>
                <span className="grid-frame grid-frame-top"></span>
                <div className="endscreen-inner">
                  <div className="endscreenContent">
                    {props.gameProgress.endPage.media ? (
                      <WPMedia media={props.gameProgress.endPage.media} />
                    ) : (
                      ''
                    )}
                    <div className="entrycontent">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: props.gameProgress.endPage.short_desc,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <span className="grid-frame grid-frame-bottom"></span>
              </div>
            )}
          </div>
          <button className={buttonColorHandler()} onClick={goToInsight}>
            Volgende
          </button>
        </div>
      </div>
    )
  } else if (insight && !debriefing && !endFeedback) {
    return (
      <div id="end-screen">
        <div id="end-screen-container" className={animation}>
          <div className="end-screen-content">
            {animation && (
              <div>
                <span className="grid-frame grid-frame-top"></span>
                <div className="endscreen-inner">
                  <div className="endscreenContent">
                    <div className="entrycontent">
                      {/* <ul>
                        {totalInsightHandler().map((insight) => (
                          <li
                            key={insight.id}
                            dangerouslySetInnerHTML={{ __html: insight.content }}
                          />
                        ))}
                        </ul> */}

                      <div className="totalInsightPoints">
                        <span>Totaal aantal Invloed Punten</span>
                        {props.gameProgress.totalInsightPoints}
                      </div>

                      <div className="roundsFedback">
                        {props.gameProgress.result.map((singleResult) => {
                          if (!singleResult) return

													const maxPoints = getMaxPoints(singleResult.loop)

                          return (
                            <div className={'rb-item ' + singleResult.status} key={singleResult.loop}>
                              <span>
                                Ronde {singleResult.loop}: <span>{singleResult.points} punten (van {maxPoints} te behalen punten)</span>
                              </span>
                              <p>{singleResult.feedbackText}</p>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <span className="grid-frame grid-frame-bottom"></span>
              </div>
            )}
          </div>
          <button className="btn btn-primary" onClick={goToDebriefing}>
            Volgende
          </button>
        </div>
      </div>
    )
  } else {
    return (
      <div id="end-screen">
        <div id="end-screen-container" className={animation}>
          <div className={contentColorHandler()}>
            {animation && (
              <div>
                <span className="grid-frame grid-frame-top"></span>
                <div className="endscreen-inner">
                  <div className="endscreenContent final">
                    <WPMedia media={props.gameData.data.config.debriefing_video} />
                    <div
                      className="entrycontent"
                      dangerouslySetInnerHTML={{
                        __html: props.gameData.data.config.debriefing_text,
                      }}
                    ></div>
                  </div>
                </div>
                <span className="grid-frame grid-frame-bottom"></span>
              </div>
            )}
          </div>
          <button className={buttonColorHandler()} onClick={onGoToHomepage}>
            Afsluiten
          </button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(setLoading(payload)),
    setEndPage: (payload) => dispatch(setEndPage(payload)),
    setGamePoint: (point) => dispatch(setGamePoint(point)),
    setEndScreen: (payload) => dispatch(setEndScreen(payload)),
  }
}

const mapStateToProps = (state) => {
  const { gameData, gameProgress, gameAction } = state
  return { gameData, gameProgress, gameAction }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndScreenFeedback)
