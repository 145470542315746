const helper = {}

helper.showInstallMessageIOS = () => {
  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent)
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone

  // Checks if should display install popup notification:
  return isIos() && !isInStandaloneMode()
}

helper.stopAllMedia = () => {
  const $videos = document.querySelectorAll('video')
  for (let $video of $videos) {
    if ($video && typeof $video.pause === 'function') {
      $video.pause()
    }
  }
}

helper.generateGameId = () => {
  return Date.now()
    .toString()
    .concat(Math.floor(Math.random() * 10000).toString())
}

export default helper
