import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import WPMedia from './WPMedia'
import { removeBigNews, showBigNews } from '../redux/actions'
import { removeTutorial } from './Tutorial'
import helper from '../lib/helper'

function BigNewsItem(props) {
  const closeDelay = 200
  const showDelay = 300
  const [isActive, setIsActive] = useState(false)
  const [item, setItem] = useState(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return function cleanup() {
      setMounted(false)
    }
  })

  useEffect(() => {
    setIsActive(false)
    if (item === null) setItem(props.item)

    const pointerSetActive = setTimeout(() => {
      setIsActive(true)
      if (item !== null) setItem(props.item)
    }, showDelay)

    return () => clearTimeout(pointerSetActive)
  }, [props.item.id])

  const onClose = (itemId) => {
    setIsActive(false)
    setTimeout(() => props.removeBigNews(itemId), closeDelay)
    removeTutorial(['tutorial-big-news-close-btn'])
  }

  const getIsClosed = () => {
    if (!item) return false

    return props.gameProgress.removedBigNewsItems.includes(item.id)
  }

  const isClosed = getIsClosed()

  useEffect(() => {
    const isShowBigNews = !isClosed && !!item
    if (isShowBigNews) {
      const pointer = setTimeout(() => {
        const isClosed2 = !props.gameProgress.removedBigNewsItems.includes(item.id) // need to refresh state manually
        props.showBigNews(isClosed2)
      }, showDelay)

      return () => clearTimeout(pointer)
    } else {
      props.showBigNews(isShowBigNews)
    }
  }, [isClosed, item])

  /*
  useEffect(() => {
    if (!props.gameAction.showBigNews) {
      helper.stopAllMedia()
    }
  }, [props.gameAction.showBigNews])
  */

  if (isClosed) return null
  if (!item) return null

  return (
    <div id="newsdetailwrapper" className={isActive ? 'active' : ''}>
      <span id="close-news-big" className="close-news-big" onClick={() => onClose(item.id)}>
        Sluiten
      </span>
      <div className="ndd-row">
        <div className="ndd-col">
          <div className="ndd-inner" id="nd-content">
            <div className="ndd-content">
              <WPMedia media={item.media} />
              {/*<div className="nd-title">
                <h3>Big clash between Protesters and the police in 25th street</h3>
              </div>*/}
              <div
                className="ndd-entry entry-content"
                dangerouslySetInnerHTML={{ __html: item.content }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeBigNews: (payload) => dispatch(removeBigNews(payload)),
    showBigNews: (payload) => dispatch(showBigNews(payload)),
  }
}

const mapStateToProps = (state) => {
  const { gameAction, gameProgress } = state
  return { gameAction, gameProgress }
}

export default connect(mapStateToProps, mapDispatchToProps)(BigNewsItem)
