import React, { useEffect, useContext, useState } from 'react'
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import {
  startTutorial,
  addSeenTutorial,
  showInsightPointsTutorial,
  showTimerTutorial,
  showInsightActionButtonTutorial,
  showAfterShowHintTutorial,
} from '../redux/actions'
import { connect } from 'react-redux'
import 'shepherd.js/dist/css/shepherd.css'

const strings = {
  exit: 'Exit',
  next: 'Next',
}

function TutorialStarter(props) {
  const tour = useContext(ShepherdTourContext)

  useEffect(() => {
    tour.start()
  }, [tour])
  return null
}

const removeTutorial = (tutorialIds) => {
  for (let id of tutorialIds) {
    const $element = document.querySelector('div[data-shepherd-step-id="' + id + '"]')
    if (!$element) continue

    $element.remove()
  }
}

function Tutorial(props) {
  useEffect(() => {
    props.showTimerTutorial()
  }, [])

  const markAsSeen = (tutorialName) => {
    return () => {
      if (!props.tutorial.seenTutorials.includes(tutorialName)) {
        props.addSeenTutorial(tutorialName)
      }
    }
  }

  const getSteps = () => {
    var steps = []

    const tutorialNames = [
      'insightPoints',
      'timer',
      'insightActionButton',
      'smallNewsItem',
      'bigNewsCloseButton',
      'insightActionOption',
      'afterSelectInsightActionOption',
      'feedbackContent',
      'endActionOption',
      'hintButton',
      'afterShowHint',
    ]

    const notSeenTutorials = tutorialNames.filter((name) => !props.tutorial.seenTutorials.includes(name))

    if (
      notSeenTutorials.includes('insightPoints') &&
      props.tutorial.showInsightPointsTutorial &&
      props.gameData.data.config.tutorial.insightPoints
    ) {
      steps.push({
        id: 'tutorial-insight-points',
        text: [props.gameData.data.config.tutorial.insightPoints],
        attachTo: { element: '#points', on: 'bottom' },
        classes: 'shepherd shepherd-welcome',
        buttons: [{ type: 'next', text: strings.next }],
        when: {
          show: () => {
            markAsSeen('insightPoints')()
          },
          destroy: () => {
            props.showInsightActionButtonTutorial()
          },
        },
      })
    }

    if (
      notSeenTutorials.includes('timer') &&
      props.tutorial.showTimerTutorial &&
      props.gameData.data.config.tutorial.timer
    ) {
      steps.push({
        id: 'tutorial-timer',
        text: [props.gameData.data.config.tutorial.timer],
        attachTo: { element: '#timer-pie', on: 'bottom' },
        classes: 'shepherd shepherd-welcome',
        buttons: [{ type: 'next', text: strings.next }],
        when: {
          show: () => {
            markAsSeen('timer')()
          },
          destroy: () => {
            props.showInsightPointsTutorial()
          },
        },
      })
    }

    if (
      notSeenTutorials.includes('insightActionButton') &&
      props.tutorial.showInsightActionButtonTutorial &&
      props.gameData.data.config.tutorial.insightActionButton
    ) {
      steps.push({
        id: 'tutorial-action-btn',
        text: [props.gameData.data.config.tutorial.insightActionButton],
        attachTo: { element: '#action-btn', on: 'top' },
        classes: 'shepherd shepherd-welcome',
        buttons: [{ type: 'next', text: strings.next }],
        when: {
          show: () => {
            markAsSeen('insightActionButton')()
          },
          destroy: () => {
          },
        },
      })
    }

    if (
      notSeenTutorials.includes('smallNewsItem') &&
      props.tutorial.showSmallNewsTutorial &&
      props.gameData.data.config.tutorial.smallNewsItem &&
      !props.gameProgress.isActionEnd
    ) {
      steps.push({
        id: 'tutorial-small-news-item',
        text: [props.gameData.data.config.tutorial.smallNewsItem],
        attachTo: { element: '#newswrapper', on: 'top' },
        classes: 'shepherd shepherd-welcome',
        buttons: [{ type: 'next', text: strings.next }],
        when: {
          show: () => {
            markAsSeen('smallNewsItem')()
            removeTutorial([])
          },
        },
      })
    }

    if (
      notSeenTutorials.includes('bigNewsCloseButton') &&
      props.tutorial.showBigNewsTutorial &&
      props.gameData.data.config.tutorial.bigNewsCloseButton
    ) {
      steps.push({
        id: 'tutorial-big-news-close-btn',
        text: [props.gameData.data.config.tutorial.bigNewsCloseButton],
        attachTo: { element: '#close-news-big', on: 'bottom' },
        classes: 'shepherd shepherd-welcome',
        buttons: [{ type: 'next', text: strings.next }],
        when: {
          show: () => {
            markAsSeen('bigNewsCloseButton')()
            removeTutorial([
              'tutorial-insight-points',
              'tutorial-timer',
              'tutorial-action-btn',
              'tutorial-small-news-item',
              'tutorial-insight-action-option',
              'tutorial-insight-action-button',
            ])
          },
        },
      })
    }

    if (
      notSeenTutorials.includes('insightActionOption') &&
      props.tutorial.insightActionOptionTutorial &&
      props.gameData.data.config.tutorial.insightActionOption
    ) {
      steps.push({
        id: 'tutorial-insight-action-option',
        text: [props.gameData.data.config.tutorial.insightActionOption],
        attachTo: { element: '#insight-actions-wrapper', on: 'top' },
        classes: 'shepherd shepherd-welcome',
        buttons: [{ type: 'next', text: strings.next }],
        when: {
          show: () => {
            markAsSeen('insightActionOption')()
            removeTutorial(['tutorial-small-news-item'])
          },
        },
      })
    }

    if (
      notSeenTutorials.includes('afterSelectInsightActionOption') &&
      props.tutorial.insightActionSubmitTutorial &&
      props.gameData.data.config.tutorial.afterSelectInsightActionOption
    ) {
      steps.push({
        id: 'tutorial-insight-action-button',
        text: [props.gameData.data.config.tutorial.afterSelectInsightActionOption],
        attachTo: { element: '#submit-btn', on: 'top' },
        classes: 'shepherd shepherd-welcome',
        buttons: [{ type: 'next', text: strings.next }],
        when: {
          show: () => {
            markAsSeen('afterSelectInsightActionOption')()
          },
        },
      })
    }

    if (
      notSeenTutorials.includes('feedbackContent') &&
      props.tutorial.feedbackTutorial &&
      props.gameData.data.config.tutorial.feedbackContent
    ) {
      steps.push({
        id: 'tutorial-feedback-button',
        text: [props.gameData.data.config.tutorial.feedbackContent],
        attachTo: { element: '#feedback', on: 'top' },
        classes: 'shepherd shepherd-welcome',
        buttons: [{ type: 'next', text: strings.next }],
        when: {
          show: () => {
            markAsSeen('feedbackContent')()
            removeTutorial([
              'tutorial-insight-points',
              'tutorial-timer',
              'tutorial-action-btn',
              'tutorial-small-news-item',
              'tutorial-big-news-close-btn',
              'tutorial-insight-action-option',
              'tutorial-insight-action-button',
            ])
          },
        },
      })
    }

    if (
      notSeenTutorials.includes('endActionOption') &&
      props.tutorial.showEndActionOptionTutorial &&
      props.gameData.data.config.tutorial.endActionOption
    ) {
      steps.push({
        id: 'tutorial-end-action-option',
        text: [props.gameData.data.config.tutorial.endActionOption],
        attachTo: { element: '#end-action-option-wrapper', on: 'top' },
        classes: 'shepherd shepherd-welcome',
        buttons: [{ type: 'next', text: strings.next }],
        when: {
          show: () => {
            markAsSeen('endActionOption')()
            removeTutorial([
              'tutorial-insight-points',
              'tutorial-timer',
              'tutorial-action-btn',
              'tutorial-small-news-item',
              'tutorial-big-news-close-btn',
              'tutorial-action-btn',
              'tutorial-insight-action-option',
              'tutorial-insight-action-button',
              'tutorial-feedback-button',
            ])
          },
          destroy: () => {
            props.showAfterShowHintTutorial()
          },
        },
      })
    }

    if (
      notSeenTutorials.includes('hintButton') &&
      props.tutorial.showHintButtonTutorial &&
      props.gameData.data.config.tutorial.hintButton
    ) {
      steps.push({
        id: 'tutorial-hint-button',
        text: [props.gameData.data.config.tutorial.hintButton],
        attachTo: { element: '#end-action-option-wrapper', on: 'top' },
        classes: 'shepherd shepherd-welcome',
        buttons: [{ type: 'next', text: strings.next }],
        when: {
          show: () => {
            markAsSeen('hintButton')()
            removeTutorial(['tutorial-end-action-option'])
          },
        },
      })
    }

    if (
      notSeenTutorials.includes('afterShowHint') &&
      props.tutorial.showAfterShowHintTutorial &&
      props.gameData.data.config.tutorial.afterShowHint
    ) {
      steps.push({
        id: 'tutorial-after-buy-hint',
        text: [props.gameData.data.config.tutorial.afterShowHint],
        attachTo: { element: '#insight-btn', on: 'top' },
        classes: 'shepherd shepherd-welcome',
        buttons: [{ type: 'next', text: strings.next }],
        when: {
          show: () => {
            markAsSeen('afterShowHint')()
          },
        },
      })
    }

    return steps
  }

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
    },
    useModalOverlay: false,
  }

  return (
    <ShepherdTour steps={getSteps()} tourOptions={tourOptions}>
      <TutorialStarter />
      {props.children}
    </ShepherdTour>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addSeenTutorial: (payload) => dispatch(addSeenTutorial(payload)),
    showInsightPointsTutorial: (payload) => dispatch(showInsightPointsTutorial(payload)),
    showTimerTutorial: (payload) => dispatch(showTimerTutorial(payload)),
    showInsightActionButtonTutorial: (payload) => dispatch(showInsightActionButtonTutorial(payload)),
    showAfterShowHintTutorial: (payload) => dispatch(showAfterShowHintTutorial(payload)),
  }
}

const mapStateToProps = (state) => {
  const { tutorial, gameData, gameProgress } = state
  return { tutorial, gameData, gameProgress }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tutorial)
export { removeTutorial }
