const INITIAL_STATE = {
  gameId: null,
  gamePoint: 0, // total point for all loops
  insightPoint: 0, // point for current loop
  loop: 1,
  selectedActionIndices: [],
  loopStartedAt: +new Date(),
  removedSmallNewsItems: [],
  removedBigNewsItems: [],
  isActionEnd: false,
  boughtHints: [],
  endPage: [],
  insightChoice: [],
  seenOnboarding: false,
  hostpotCoordinate: {
    x: 0,
    y: 0,
  },
  // result related
  totalInsightPoints: 0,
  result: [
    // [loop int]: { loop: int, feedbackText: string, status: negative|neutral|positive, points: int }
  ],
}

const gameProgressReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'START_GAME':
      var { seenOnboarding, loopStartedAt } = state
      seenOnboarding = true
      loopStartedAt = +new Date()
      return { ...state, seenOnboarding, loopStartedAt }
    case 'SET_INSIGHT_POINT':
      var { insightPoint } = state
      insightPoint = action.payload
      return { ...state, insightPoint }
    case 'SET_GAME_POINT':
      var { gamePoint } = state
      gamePoint = action.payload
      return { ...state, gamePoint }
    case 'SET_LOOP':
      var { loop } = state
      loop = action.payload
      return { ...state, loop }
    case 'SET_SELECTED_ACTIONS':
      var { selectedActionIndices } = state
      selectedActionIndices = action.payload
      return { ...state, selectedActionIndices }
    case 'SET_BOUGHT_HINTS':
      var { boughtHints } = state
      boughtHints = action.payload
      return { ...state, boughtHints }
    case 'REMOVE_SMALL_NEWS':
      var { removedSmallNewsItems } = state
      if (removedSmallNewsItems.includes(action.payload)) return { ...state }

      removedSmallNewsItems.push(action.payload)
      return { ...state, removedSmallNewsItems }
    case 'REMOVE_BIG_NEWS':
      var { removedBigNewsItems } = state
      if (removedBigNewsItems.includes(action.payload)) return { ...state }

      removedBigNewsItems.push(action.payload)
      return { ...state, removedBigNewsItems }
    case 'SET_IS_ACTION_END':
      var { isActionEnd } = state
      isActionEnd = action.payload
      return { ...state, isActionEnd }
    case 'SET_END_PAGE':
      var { endPage } = state
      endPage = action.payload
      return { ...state, endPage }
    case 'SET_INSIGHT_CHOICE':
      var { insightChoice } = state
      insightChoice = action.payload
      return { ...state, insightChoice }
    case 'SET_TOTAL_INSIGHT_POINT':
      var { totalInsightPoints } = state
      totalInsightPoints = action.payload
      return { ...state, totalInsightPoints }
    case 'SET_HOTSPOT_COORDINATE':
      var { hostpotCoordinate } = state
      hostpotCoordinate = action.payload
      return { ...state, hostpotCoordinate }
    case 'SET_RESULT':
      var { result } = state
      result = action.payload
      return { ...state, result }
    case 'START_LOOP':
      return {
        ...state,
        isActionEnd: false,
        insightPoint: 0,
        selectedActionIndices: [],
        loopStartedAt: +new Date(),
        removedSmallNewsItems: [],
        boughtHints: [],
      }
    case 'RESET_GAME_PROGRESS':
      var loopStartedAt = +new Date()
      return { ...INITIAL_STATE, loopStartedAt }
    case 'SET_GAME_ID':
      var { gameId } = state
      gameId = action.payload
      return { ...state, gameId }

    default:
      return state
  }
}

export default gameProgressReducer
