import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setLoading, setEndPage } from '../redux/actions'
import EndScreenFeedback from '../components/EndScreenFeedback'

function EndScreen(props) {
  const validate = () => {
    if (props.gameData.lastUpdate === 0) window.location.href = '/'
  }

  validate()

  const goToHomePage = () => {
    props.setLoading(true)
    setTimeout(() => {
      props.history.push({ pathname: '/' })
      props.setLoading(false)
    }, 250)
  }

  useEffect(() => {
    const array = props.gameData.data.endGame
    const data = array.find(({ minimum_point }) => props.gameProgress.gamePoint >= minimum_point)

    props.setEndPage(data)
  }, [props.gameProgress.gamePoint])

  return (
    <div id="homeScreen-content">
      <div className="end-screen-inner">
        <EndScreenFeedback goToHomePage={goToHomePage} />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(setLoading(payload)),
    setEndPage: (payload) => dispatch(setEndPage(payload)),
  }
}

const mapStateToProps = (state) => {
  const { gameData, gameProgress } = state
  return { gameData, gameProgress }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndScreen)
