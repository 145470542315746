import { connect } from 'react-redux'
import { setLoading } from '../redux/actions'

function Page404(props) {
  const goToHomePage = () => {
    props.setLoading(true)
    setTimeout(() => {
      props.history.push({ pathname: '/' })
      props.setLoading(false)
    }, 250)
  }

  return (
    <div id="homeScreen-content">
      <div className="homescreen-container">
        <h4>404 - Not Found</h4>
        <br />
        <button className="btn btn-primary" onClick={goToHomePage}>
          Go To Homepage
        </button>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => dispatch(setLoading(payload)),
  }
}

export default connect(null, mapDispatchToProps)(Page404)
