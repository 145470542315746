import React, { useState } from 'react'

function MediaTag(props) {
  var videoHandler

  const onVideoClick = () => {
    if (!videoHandler) return

    if (props.videoPlayed) {
      videoHandler.pause()
    } else {
      videoHandler.play()
    }
  }

  const videoAutoPlay = props.disableAutoPlay ? false : true

  if (props.mediaType === 'image') return <img src={props.mediaData.url} />
  else if (props.mediaType === 'video')
    return (
      <>
        <video
          ref={(video) => (videoHandler = video)}
          width="320"
          height="240"
          controls
          preload="auto"
          onEnded={props.onVideEnd}
          playsInline="playsInline"
          poster={props.poster}
          onPlay={() => props.setVideoPlayed(true)}
          onPlaying={() => props.setVideoPlayed(true)}
          onPause={() => props.setVideoPlayed(false)}
          autoPlay={videoAutoPlay}
          onClick={() => console.log('test')}
          loop={false}
        >
          {' '}
          {/* Dikasih class playing ketika play, paused ketika paused*/}
          <source src={props.mediaData.url} />
          Your browser does not support the video tag.
        </video>

        <button id="playbutton" title="play" type="button" onClick={onVideoClick}>
          Play
        </button>
      </>
    )
  else if (props.mediaType === 'audio')
    return (
      <audio controls preload="auto">
        <source src={props.mediaData.url} />
        Your browser does not support the audio element.
      </audio>
    )
  return <p>Unknown media type</p>
}

function WPMedia(props) {
  const [videoPlayed, setVideoPlayed] = useState(false)

  const mediaData = Array.isArray(props.media) ? props.media[0] : props.media
  const getPoster = () => {
    if (!Array.isArray(props.media)) return null

    const hasImage = props.media.find((media) => media.type === 'image')
    const hasVideo = props.media.find((media) => media.type === 'video')
    if (hasImage && hasVideo) {
      return hasImage.url
    } else {
      return null
    }
  }

  const getType = () => {
    if (!Array.isArray(props.media)) return 'unknown'
    const hasVideo = props.media.find((media) => media.type === 'video')
    if (hasVideo) return 'video'
    else return props.media[0].type
  }

  const onVideEnd = () => {
    const selector = document.querySelector('#newsdetailwrapper video')
    if (selector && typeof selector.webkitExitFullscreen === 'function') {
      selector.webkitExitFullscreen()
    }
  }

  const getMediaData = () => {
    if (!Array.isArray(props.media)) return null

    const hasVideo = props.media.find((media) => media.type === 'video')
    if (hasVideo) return hasVideo
    else return props.media[0]
  }

  if (!mediaData || !mediaData.filesize) return null

  const videoClass = videoPlayed ? 'paused' : 'play'
  return (
    <div className={'thumbnail ' + mediaData.type + ' ' + videoClass}>
      <MediaTag
        mediaData={getMediaData()}
        onVideEnd={onVideEnd}
        poster={getPoster()}
        mediaType={getType()}
        setVideoPlayed={setVideoPlayed}
        videoPlayed={videoPlayed}
        disableAutoPlay={props.disableAutoPlay}
      />
    </div>
  )
}

export default WPMedia
