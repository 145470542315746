function Map(props) {
  var backgroundMap = { backgroundImage: 'url(assets/images/map.jpg)' }
  var locationStyle = { top: 0, left: 0 }

  if (props.coordinate) {
    locationStyle = { top: props.coordinate.y + '%', left: props.coordinate.x + '%' }
  }

  const showAlert = !!props.coordinate && props.coordinate.x !== 0 && props.coordinate.y !== 0

  return (
    <div className="map-wrapper">
      <div className="map-holder" style={backgroundMap}>
        {showAlert && (
          <span className="locationAlert" style={locationStyle}>
            <span>Alert</span>
            <span className="glow"></span>
          </span>
        )}
        <img src="assets/images/map.jpg" />
      </div>
    </div>
  )
}

export default Map
