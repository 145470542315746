import {
  setActionClosed,
  setActionOpened,
  setFeedbackOpened,
  setSelectedActions,
  setFeedbackData,
  setGamePoint,
  setLoop,
  setIsActionEnd,
  startLoop,
  setLoading,
  setBoughtHints,
  setInsightPoint,
  setWarningText,
  showEndActionOptionTutorial,
  showHintButtonTutorial,
  showEndActionSubmitTutorial,
  setResult,
} from '../redux/actions'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { removeTutorial } from './Tutorial'

function EndActions(props) {
  const [selectAction, setSelectAction] = useState(null)
  const [actionOptions, setActionOptions] = useState([])
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [hintPoints, setHintPoints] = useState([])
  const [openHints, setOpenHints] = useState(false)
  const [hint, setHint] = useState(null)
  const [hintClassActive, setHintClassActive] = useState('')

  useEffect(() => {
    const actEnds = props.gameData.data.actionEnds
    setActionOptions(
      actEnds.filter((actInsight) => {
        const sameLoop = actInsight.loop == props.gameProgress.loop
        return sameLoop
      })
    )
  }, [props.gameData, props.gameProgress.loop])

  const onClickActionOption = (actionId) => {
    props.showEndActionSubmitTutorial()
    setSubmitDisabled(false)
    setSelectAction(actionId)
  }

  const onClickInsightBtn = () => {
    if (!openHints) {
      setTimeout(() => {
        props.showHintButtonTutorial()
        removeTutorial(['tutorial-after-buy-hint'])
      }, 500)
    } else {
      removeTutorial(['tutorial-hint-button'])
    }
    setOpenHints(!openHints)
  }

  const onSubmitClick = () => {
    const updateResult = () => {
      const getStatus = (singleResult) => {
        const round = props.gameData.data.rounds.find((round) => round.loop == props.gameProgress.loop) || {
          id: -999 + props.gameProgress.loop,
          loop: props.gameProgress.loop,
          positiveThrehold: 100,
          neutralThrehold: 0,
        }

        // const actionEnd = props.gameData.data.actionEnds.find((actInsight) => actInsight.id == selectAction)

        if (singleResult.points >= round.positiveThrehold) {
          return 'positive'
        } else if (singleResult.points >= round.neutralThrehold) {
          return 'neutral'
        } else {
          return 'negative'
        }
      }

      const getResult = () => {
        const defaultSingleResult = {
          loop: props.gameProgress.loop,
          feedbackText: '-',
          points: 0,
        }

        const singleResult =
          props.gameProgress.result.find(
            (resultSingle) => resultSingle && resultSingle.loop == props.gameProgress.loop
          ) || defaultSingleResult

        singleResult.status = getStatus(singleResult)

        const cloneResult = [...props.gameProgress.result]
        cloneResult[props.gameProgress.loop] = singleResult

        return cloneResult
      }

      props.setResult(getResult())
    }

    const getFeedbackData = () => {
      const actionEnd = props.gameData.data.actionEnds.find((actInsight) => actInsight.id == selectAction)

      var status
      if (actionEnd.points > 0) status = 'positive'
      else if (actionEnd.points === 0) status = 'neutral'
      else status = 'negative'

      const onClose = () => {
        removeTutorial(['tutorial-feedback-button'])
        props.setLoading(true)

        setTimeout(() => {
          const calculatePoint = () => {
            const actionEnd = props.gameData.data.actionEnds.find(
              (actInsight) => actInsight.id == selectAction
            )
            const point = props.gameProgress.gamePoint + parseInt(actionEnd.points)
            return point
          }

          props.setGamePoint(calculatePoint())

          const newLoop = props.gameProgress.loop + 1
          props.setLoop(newLoop)
          props.setActionClosed()
          props.startLoop()
          props.setLoading(false)
        }, 250)
      }

      return {
        status,
        point: 1,
        text: actionEnd.feedback,
        onClose,
        referer: 'END_ACTION',
      }
    }

    updateResult()
    setSubmitDisabled(true)
    props.setFeedbackData(getFeedbackData())
    props.setFeedbackOpened()
    removeTutorial([
      'tutorial-end-action-option',
      'tutorial-hint-button',
      'tutorial-after-buy-hint',
      'tutorial-end-action-submit',
    ])
  }

  const getHints = () => {
    return props.gameData.data.hints
      .sort((a, b) => (a.points < b.points ? -1 : 1))
      .filter((hint) => {
        const sameLoop = hint.loop == props.gameProgress.loop
        return sameLoop
      })
      .map((hint) => {
        hint.available = !props.gameProgress.boughtHints.includes(hint.id)
        return hint
      })
  }

  const getQuestion = () => {
    if (!props.gameData.data.questions || !props.gameData.data.questions.length) return

    const question = props.gameData.data.questions.find(
      (question) => question.loop === props.gameProgress.loop
    )

    if (!question) return
    return question.text
  }

  const buyHint = (argHint) => {
    const newInsightPoint = props.gameProgress.insightPoint - argHint.points
    if (newInsightPoint < 0) {
      return props.setWarningText('Je hebt niet genoeg punten om deze hint te kopen')
    }

    setOpenHints(false)
    setHint(argHint)
    props.setInsightPoint(newInsightPoint)
    const newBoughtHints = [...props.gameProgress.boughtHints]
    newBoughtHints.push(argHint.id)
    props.setBoughtHints(newBoughtHints)
  }

  useEffect(() => {
    setHintClassActive('')

    if (!hint) return
    setTimeout(() => setHintClassActive('active'), 900)
  }, [hint])

  useEffect(() => {
    props.showEndActionOptionTutorial()
  }, [])

  const hints = getHints()

  if (props.gameAction.actionState === 'FEEDBACK_OPENED') return null

  const showHint = hint && hintClassActive === 'active'

  return (
    <div
      className={
        'actions-wrapper end-action ' +
        (openHints ? 'insight-chooser-open' : '') +
        ' ' +
        (hints.length ? 'with-insight' : '')
      }
      id="insight-content"
    >
      {showHint && (
        <div id="insight-container" className={hintClassActive}>
          <div className="insights">
            <div
              className="insight-item"
              id="insight"
              dangerouslySetInnerHTML={{ __html: hint.feedback }}
            ></div>
          </div>
        </div>
      )}
      <div className="actions-row">
        <div className="actions-col">
          <span className="action-grid grid-frame grid-frame-top"></span>
          <div className={'action-content ' + (openHints ? 'active' : '')} id="end-action-option-wrapper">
            <div className="action-inner">
              <div className="insight-points">
                {hints.map((hint) => (
                  <button
                    key={hint.id}
                    type="button"
                    className={
                      'btn insight-button ' +
                      (props.gameProgress.insightPoint < hint.points ? 'disabled' : '')
                    }
                    disabled={!hint.available}
                    onClick={() => buyHint(hint)}
                  >
                    {hint.points} Punten
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="action-content active" id="action-content">
            <a className="close-action" title="close">
              Sluiten
            </a>
            <div className="action-inner">
              <div className="actions">
                <h3 className="question-title">{getQuestion()}</h3>
                {actionOptions.map((actionOption) => (
                  <label
                    className="action-item"
                    key={actionOption.id}
                    onClick={() => onClickActionOption(actionOption.id)}
                  >
                    <input type="radio" name="option" />
                    <span dangerouslySetInnerHTML={{ __html: actionOption.content }}></span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          {!!hints.length && (
            <button
              id="insight-btn"
              className="btn btn-insight"
              title="See some insights"
              onClick={onClickInsightBtn}
            >
              <span className="blur-bg"></span>
              <img src="./assets/images/hint.svg" alt="hint icon" />
              Hint<span className="close">Sluiten</span>
            </button>
          )}
          <button id="action-btn" className="btn btn-primary active">
            Acties
          </button>
          <button
            id="submit-btn"
            className="btn btn-primary"
            disabled={submitDisabled}
            onClick={onSubmitClick}
          >
            Invoeren
          </button>
          <span className="action-grid grid-frame grid-frame-bottom"></span>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGamePoint: (payload) => dispatch(setGamePoint(payload)),
    setFeedbackData: (payload) => dispatch(setFeedbackData(payload)),
    setFeedbackOpened: (payload) => dispatch(setFeedbackOpened(payload)),
    setActionClosed: (payload) => dispatch(setActionClosed(payload)),
    setLoop: (payload) => dispatch(setLoop(payload)),
    setIsActionEnd: (payload) => dispatch(setIsActionEnd(payload)),
    startLoop: (payload) => dispatch(startLoop(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
    setBoughtHints: (payload) => dispatch(setBoughtHints(payload)),
    setInsightPoint: (payload) => dispatch(setInsightPoint(payload)),
    setWarningText: (payload) => dispatch(setWarningText(payload)),
    showEndActionOptionTutorial: (payload) => dispatch(showEndActionOptionTutorial(payload)),
    showHintButtonTutorial: (payload) => dispatch(showHintButtonTutorial(payload)),
    showEndActionSubmitTutorial: (payload) => dispatch(showEndActionSubmitTutorial(payload)),
    setResult: (payload) => dispatch(setResult(payload)),
  }
}

const mapStateToProps = (state) => {
  const { gameData, gameAction, gameProgress } = state
  return { gameData, gameAction, gameProgress }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndActions)
