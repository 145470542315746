const INITIAL_STATE = {
  actions: [],
  actionState: 'ACTION_CLOSED', // enum: ACTION_CLOSED, ACTION_OPENED, FEEDBACK_OPENED
  isLoading: false,
  feedback: {
    status: 'neutral',
    point: 0,
    text: 'feedback-text',
    onClose: () => {
      console.log('on close default')
    },
  },
  endScreen: {
    insight: false,
    debriefing: false,
  },
  warningText: null,
  showBigNews: false,
}

const gameActionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ACTIONS':
      var { actions } = state
      actions = action.payload
      return { ...state, actions }
    case 'SHOW_BIG_NEWS':
      var { showBigNews } = state
      showBigNews = action.payload
      return { ...state, showBigNews }
    case 'SET_ACTION_STATE':
      var { actionState } = state
      actionState = action.payload
      return { ...state, actionState }
    case 'SET_FEEDBACK_DATA':
      var { feedback } = state
      feedback = action.payload
      return { ...state, feedback }
    case 'SET_LOADING':
      var { isLoading } = state
      isLoading = action.payload
      return { ...state, isLoading }
    case 'SET_END_SCREEN':
      var { endScreen } = state
      endScreen = action.payload
      return { ...state, endScreen }
    case 'SET_WARNING_TEXT':
      var { warningText } = state
      warningText = action.payload
      return { ...state, warningText }
    case 'RESET_GAME_ACTION':
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default gameActionReducer
