import { combineReducers } from 'redux'
import gameActionReducer from './reducers/game-action'
import gameProgressReducer from './reducers/game-progress'
import gameDataReducer from './reducers/game-data'
import tutorialReducer from './reducers/tutorial'

export default combineReducers({
  gameAction: gameActionReducer,
  gameProgress: gameProgressReducer,
  gameData: gameDataReducer,
  tutorial: tutorialReducer,
})
