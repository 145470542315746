import { useEffect, useState } from 'react'

function UpcomingNews({ gameData, gameLoop, lapsed }) {
	const [lapsedUp, setLapsedUp] = useState(null)
	const [mounted, setMounted] = useState(false)

	const bigNewsItems = gameData.bigNewsItems.sort((a, b) => {
		return b.time - a.time
	})

	useEffect(() => {
		setMounted(true)
		return function cleanup() {
			setMounted(false)
		}
	}, [])

	useEffect(() => {
		setLapsedUp(lapsed)
	}, [lapsed])

	useEffect(() => {
		if (!mounted) return
		if (lapsedUp === null || lapsedUp === 0) return

		setTimeout(() => {
			setLapsedUp(lapsedUp + 1000)
		}, 1000)
	}, [lapsedUp])

	return (
		<div className="upcomingNews">
			<div className="upcomingNews__wrapper">
				{bigNewsItems
					.slice(0)
					.reverse()
					.map(({ name, id, loop, time }) =>
						loop === gameLoop ? (
							<div className={`upcomingNews__item ${lapsedUp / 1000 > time ? 'is-check' : ''}`} key={id}>
								<div className="upcomingNews__content">
									<p>{name}</p>
								</div>
								<div className="upcomingNews__status">
									<div className="upcomingNews__check"></div>
								</div>
							</div>
						) : (
							''
						)
					)}
			</div>
		</div>
	)
}
export default UpcomingNews
