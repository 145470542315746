import { connect } from 'react-redux'

function Points(props) {
  return (
    <div id="points">
      <div className="inner">
        <span>Invloed punten</span>
        <div id="insight-point">{props.gameProgress.insightPoint}</div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const mapStateToProps = (state) => {
  const { gameProgress } = state
  return { gameProgress }
}

export default connect(mapStateToProps, mapDispatchToProps)(Points)
