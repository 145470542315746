import WPMedia from './WPMedia'
import { connect } from 'react-redux'

function PreloadWPMedia(props) {
  // put all assets you want to preload and cache here

  try {
    const bigNewsItemsExist = props.gameData.data.bigNewsItems.length
  } catch (e) {
    return null
  }

  return (
    <div style={{ display: 'none' }}>
      {props.gameData.data.bigNewsItems.map((item) => (
        <WPMedia key={item.id} media={item.media} disableAutoPlay={true} />
      ))}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { gameData } = state
  return { gameData }
}

export default connect(mapStateToProps, null)(PreloadWPMedia)
