import { useEffect, useState } from 'react'
import SvgPieTimer from '../lib/SvgPieTimer'

function Timer(props) {
  const [runningOut, setRunningOut] = useState(false)

  return (
    <div id="timer-pie" className={runningOut ? 'runningOut' : ''}>
      <SvgPieTimer
        height={20}
        width={20}
        onEnded={props.onEnded}
        setRunningOut={setRunningOut}
        duration={props.duration}
        loops={1}
        reverse={false}
        inverse={false}
        lapsed={props.lapsed}
      />
    </div>
  )
}

export default Timer
