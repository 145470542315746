import { connect } from 'react-redux'

function SmallNewsItem(props) {
  return (
    <div className="news-item">
      <div className="ni-inner">
        <div className="news-item-detail">
          <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
        </div>
        <span className="close-small-news-item" onClick={props.onClick}></span>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

const mapStateToProps = (state) => {
  const { gameAction } = state
  return { gameAction }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmallNewsItem)
