import React, { useEffect, useState } from 'react'
import NewsItems from '../components/NewsItems'
import Timer from '../components/Timer'
import Feedback from '../components/Feedback'
import InsightActions from '../components/InsightActions'
import EndActions from '../components/EndActions'
import Onboarding from '../components/Onboarding'
import MapC from '../components/Map'
import Points from '../components/Points'
import Warning from '../components/Warning'
import Tutorial from '../components/Tutorial'
import config from '../config'
import request from '../lib/request'
import { setIsActionEnd, setActionClosed, setLoading, showBigNews } from '../redux/actions'

import { connect } from 'react-redux'
import UpcomingNews from '../components/UpcomingNews'

function LevelScreen(props) {
  const validate = () => {
    if (props.gameData.lastUpdate === 0) window.location.href = '/'
  }

  validate()

  const [lapsed, setLapsed] = useState(0)

  useEffect(() => {
    if (props.gameProgress.isActionEnd) return

    const lapsed = +new Date() - props.gameProgress.loopStartedAt
    setLapsed(lapsed)
    return function cleanup() {
      setLapsed(0)
    }
  }, [props.gameProgress.loopStartedAt, props.gameProgress.isActionEnd])

  useEffect(() => {
    if (props.gameProgress.isActionEnd) {
      props.showBigNews(false)
    }
  }, [props.gameProgress.isActionEnd])

  const timerConfig =
    props.gameProgress.loop === 1
      ? props.gameData.data.config.firstLoopTimer
      : props.gameData.data.config.timerPerLoopInSeconds
  const timerDuration = timerConfig * 1000

  const onTimerEnded = () => {
    props.setLoading(true)
    setTimeout(() => {
      props.setActionClosed()
      props.setIsActionEnd(true)
      props.setLoading(false)
    }, 250)
  }

  useEffect(() => {
    if (props.gameProgress.loop > props.gameData.data.config.loopTotal) {
      props.setLoading(true)
      setTimeout(() => {
        request.postAnalytic(props.gameProgress.gameId, 'finish')
        props.history.push({ pathname: '/end' })
        props.setLoading(false)
      }, 250)
    }
  }, [props.gameProgress.loop, props.gameData.data.config.loopTotal])

  if (props.gameProgress.loop > props.gameData.data.config.loopTotal) return null

  if (!props.gameProgress.seenOnboarding) {
    return <Onboarding gameData={props.gameData.data} history={props.history} />
  }

  if (props.gameProgress.isActionEnd) {
    return (
      <div id="page-main-screen">
        <Tutorial>
          <MapC coordinate={null} />
          <Points />
          <EndActions gameData={props.gameData.data} />
          <Feedback gameData={props.gameData.data} />
          <Warning />
        </Tutorial>
      </div>
    )
  } else {
    return (
      <div id="page-main-screen">
        <Tutorial>
          <MapC coordinate={props.gameProgress.hostpotCoordinate} /> 
					<div className="gameRound">ROUND {props.gameProgress.loop}</div>
          <Timer duration={timerDuration} onEnded={onTimerEnded} lapsed={lapsed} />
          <UpcomingNews gameData={props.gameData.data} gameLoop={props.gameProgress.loop} lapsed={lapsed} />
          <Points />
          <NewsItems gameData={props.gameData.data} duration={timerDuration} lapsed={lapsed} />
          <InsightActions gameData={props.gameData.data} />
          <Feedback gameData={props.gameData.data} />
        </Tutorial>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsActionEnd: (payload) => dispatch(setIsActionEnd(payload)),
    setActionClosed: (payload) => dispatch(setActionClosed(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
    showBigNews: (payload) => dispatch(showBigNews(payload)),
  }
}

const mapStateToProps = (state) => {
  const { gameData, gameProgress } = state
  return { gameData, gameProgress }
}

export default connect(mapStateToProps, mapDispatchToProps)(LevelScreen)
