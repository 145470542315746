import {
  setActionClosed,
  setActionOpened,
  setFeedbackOpened,
  setSelectedActions,
  setFeedbackData,
  setInsightPoint,
  setInsightChoice,
  setTotalInsightPoint,
  showInsightActionOptionTutorial,
  showInsightActionSubmitTutorial,
  setResult,
  showTimerTutorial,
} from '../redux/actions'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { removeTutorial } from './Tutorial'

function InsightActions(props) {
  const [selectAction, setSelectAction] = useState(null)
  const [actionOptions, setActionOptions] = useState([])
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const getDefaultAction = () => {
    const getDefaultInsightAction = () => {
      try {
        return props.gameData.data.config.defaultInsightAction
      } catch (e) {
        return 'Niets doen'
      }
    }

    const getDefaultInsightActionFeedback = () => {
      try {
        return props.gameData.data.config.defaultInsightActionFeedback
      } catch (e) {
        return 'Niets doen'
      }
    }

    const defaultAction = {
      id: -1,
      title: getDefaultInsightAction(),
      name: getDefaultInsightAction(),
      content: '<p>' + getDefaultInsightAction() + '</p>',
      loop: props.gameProgress.loop,
      points: 0,
      feedback: '<p>' + getDefaultInsightActionFeedback() + '</p>',
    }

    return defaultAction
  }

  useEffect(() => {
    const actInsights = props.gameData.data.actionInsights

    setActionOptions([
      ...actInsights.filter((actInsight) => {
        const sameLoop = actInsight.loop == props.gameProgress.loop
        const notInSelectedAction = !props.gameProgress.selectedActionIndices.includes(
          parseInt(actInsight.id)
        )
        return sameLoop && notInSelectedAction
      }),
      getDefaultAction(),
    ])
  }, [props.gameData, props.gameProgress.loop, props.gameProgress.selectedActionIndices])

  const onClickActionOption = (actionId) => {
    setSubmitDisabled(false)
    setSelectAction(actionId)
    props.showInsightActionSubmitTutorial()
    removeTutorial([
      'tutorial-small-news-item',
      'tutorial-insight-action-option',
      'tutorial-insight-action-button',
    ])
  }

  const onSubmitClick = () => {
    const getFeedbackData = () => {
      const actionInsight =
        props.gameData.data.actionInsights.find((actInsight) => actInsight.id == selectAction) ||
        getDefaultAction()

      var status
      if (!actionInsight) status = 'neutral'
      else if (actionInsight.points > 0) status = 'positive'
      else if (actionInsight.points === 0) status = 'neutral'
      else status = 'negative'

      const onClose = () => {
        props.setActionClosed()
        removeTutorial([
          'tutorial-insight-points',
          'tutorial-timer',
          'tutorial-action-btn',
          'tutorial-small-news-item',
          'tutorial-big-news-close-btn',
          'tutorial-insight-action-option',
          'tutorial-insight-action-button',
          'tutorial-feedback-button',
        ])
      }

      return {
        status,
        point: actionInsight.points,
        text: actionInsight.feedback,
        onClose,
        referer: 'INSIGHT_ACTION',
      }
    }

    const calculatePoint = () => {
      const actionInsight =
        props.gameData.data.actionInsights.find((actInsight) => actInsight.id == selectAction) ||
        getDefaultAction()

      const point = props.gameProgress.insightPoint + parseInt(actionInsight.points)
      return point
    }

    const insertChoice = () => {
      const actionInsight =
        props.gameData.data.actionInsights.find((actInsight) => actInsight.id == selectAction) ||
        getDefaultAction()
      const actsId = actionInsight.id

      return [...props.gameProgress.insightChoice, actsId]
    }

    const insertTotalInsightPoint = () => {
      const actionInsight =
        props.gameData.data.actionInsights.find((actInsight) => actInsight.id == selectAction) ||
        getDefaultAction()
      const totalPoint = props.gameProgress.totalInsightPoints + actionInsight.points

      return totalPoint
    }

    const getResult = () => {
      const getFeedback = (singleResult) => {
        const round = props.gameData.data.rounds.find((round) => round.loop == singleResult.loop) || {
          id: -999 + singleResult.loop,
          loop: singleResult.loop,
          positiveThrehold: 100,
          neutralThrehold: 0,
        }

        if (singleResult.points >= round.positiveThrehold) {
          return { status: 'positive', text: round.positiveFeedback || '-' }
        } else if (singleResult.points >= round.neutralThrehold) {
          return { status: 'neutral', text: round.neutralFeedback || '-' }
        } else {
          return { status: 'negative', text: round.negativeFeedback || '-' }
        }
      }

      const actionInsight =
        props.gameData.data.actionInsights.find((actInsight) => actInsight.id == selectAction) ||
        getDefaultAction()
      const defaultSingleResult = {
        loop: props.gameProgress.loop,
        feedbackText: '-',
        points: 0,
      }

      const singleResult =
        props.gameProgress.result.find(
          (resultSingle) => resultSingle && resultSingle.loop == props.gameProgress.loop
        ) || defaultSingleResult

      singleResult.points = singleResult.points + actionInsight.points

      const feedback = getFeedback(singleResult)
      singleResult.feedbackText = feedback.text
      singleResult.status = 'unknown'

      const cloneResult = [...props.gameProgress.result]
      cloneResult[props.gameProgress.loop] = singleResult

      return cloneResult
    }

    const newSelectedActionIndices = [...props.gameProgress.selectedActionIndices]
    newSelectedActionIndices.push(selectAction)

    setSubmitDisabled(true)
    props.setSelectedActions(newSelectedActionIndices)
    props.setFeedbackData(getFeedbackData())
    props.setFeedbackOpened()
    props.setInsightPoint(calculatePoint())
    props.setInsightChoice(insertChoice())
    props.setTotalInsightPoint(insertTotalInsightPoint())
    props.setResult(getResult())

    // TODO animate
    // fade in #feedback
    // fade out .actions-wrapper
  }

  useEffect(() => {
    const round = props.gameData.data.rounds.find((round) => round.loop == props.gameProgress.loop) || {
      id: -999 + props.gameProgress.loop,
      loop: props.gameProgress.loop,
      positiveThrehold: 100,
      neutralThrehold: 0,
    }

    const config = props.gameData.data.config
    const defaultSingleResult = {
      loop: props.gameProgress.loop,
      feedbackText: round.neutralThrehold > 0 ? round.negativeFeedback : round.neutralFeedback,
      points: 0,
      status: 'unknown',
    }

    const singleResult =
      props.gameProgress.result.find(
        (resultSingle) => resultSingle && resultSingle.loop == props.gameProgress.loop
      ) || defaultSingleResult

    const cloneResult = [...props.gameProgress.result]
    cloneResult[props.gameProgress.loop] = singleResult

    props.setResult(cloneResult)
  }, [props.gameProgress.loop])

  useEffect(() => {
    if (props.gameAction.actionState === 'ACTION_OPENED') {
      setTimeout(() => {
        props.showInsightActionOptionTutorial()
      }, 500)
    }
  }, [props.gameAction.actionState])

  const onActionClose = () => {
    if (props.tutorial.showInsightActionButtonTutorial) {
      props.showTimerTutorial()
    }

    removeTutorial([
      'tutorial-small-news-item',
      'tutorial-insight-action-option',
      'tutorial-insight-action-button',
    ])
    props.setActionClosed()
  }

  const onActionButtonClick = () => {
    removeTutorial(['tutorial-action-btn', 'tutorial-small-news-item'])
    props.setActionOpened()
  }

  const isActionOptionDisabled = (actionOptionId) => {
    const hasSelectedAction = !!props.gameProgress.selectedActionIndices.length
    const loop = props.gameProgress.loop
    const notFirstAction = actionOptionId != props.gameData.data.config.tutorial.firstInsightAction
    return loop == 1 && notFirstAction && !hasSelectedAction
  }

  if (props.gameAction.actionState === 'FEEDBACK_OPENED') return null

  return (
    <div className="actions-wrapper">
      <div className="actions-row">
        <div className="actions-col">
          <span className="action-grid grid-frame grid-frame-top"></span>
          <div
            className={'action-content ' + (props.gameAction.actionState === 'ACTION_OPENED' && 'active')}
            id="action-content"
          >
            <a className="close-action" title="close" onClick={onActionClose}>
              Sluiten
            </a>
            <div className="action-inner">
              <div id="insight-actions-wrapper" className="actions">
                <h5 className="actionHeader">Wat wil jij doen?</h5>
                {actionOptions.map((actionOption, index) => {
                  const actionItemDisabledClass = isActionOptionDisabled(actionOption.id) ? 'disabled' : ''
                  return (
                    <label
                      className={'action-item ' + actionItemDisabledClass}
                      key={actionOption.id}
                      onClick={() => onClickActionOption(actionOption.id)}
                      id={'action-item-' + index}
                    >
                      <input type="radio" name="option" disabled={isActionOptionDisabled(actionOption.id)} />
                      <span dangerouslySetInnerHTML={{ __html: actionOption.content }}></span>
                    </label>
                  )
                })}
              </div>
            </div>
          </div>
          <button
            id="action-btn"
            className={'btn btn-primary ' + (props.gameAction.actionState === 'ACTION_OPENED' && 'active')}
            onClick={onActionButtonClick}
          >
            Acties
          </button>
          <button
            id="submit-btn"
            className="btn btn-primary"
            onClick={onSubmitClick}
            disabled={submitDisabled}
          >
            Invoeren
          </button>
          <span className="action-grid grid-frame grid-frame-bottom"></span>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActionClosed: (payload) => dispatch(setActionClosed(payload)),
    setActionOpened: (payload) => dispatch(setActionOpened(payload)),
    setFeedbackOpened: (payload) => dispatch(setFeedbackOpened(payload)),
    setSelectedActions: (payload) => dispatch(setSelectedActions(payload)),
    setFeedbackData: (payload) => dispatch(setFeedbackData(payload)),
    setInsightPoint: (payload) => dispatch(setInsightPoint(payload)),
    setInsightChoice: (payload) => dispatch(setInsightChoice(payload)),
    setTotalInsightPoint: (payload) => dispatch(setTotalInsightPoint(payload)),
    showInsightActionOptionTutorial: (payload) => dispatch(showInsightActionOptionTutorial(payload)),
    showInsightActionSubmitTutorial: (payload) => dispatch(showInsightActionSubmitTutorial(payload)),
    setResult: (payload) => dispatch(setResult(payload)),
    showTimerTutorial: (payload) => dispatch(showTimerTutorial(payload)),
  }
}

const mapStateToProps = (state) => {
  const { gameData, gameAction, gameProgress, tutorial } = state
  return { gameData, gameAction, gameProgress, tutorial }
}

export default connect(mapStateToProps, mapDispatchToProps)(InsightActions)
