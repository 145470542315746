import { useEffect, useState } from 'react'
import Slider from 'react-slick'
import SmallNewsItem from './SmallNewsItem'
import BigNewsItem from './BigNewsItem'
import { connect } from 'react-redux'
import {
  removeSmallNews,
  showBigNewsTutorial,
  setHotspotCoordinate,
  showSmallNewsTutorial,
} from '../redux/actions'

function NewsItems(props) {
  const [refSlider, setRefSlider] = useState(null)
  const [lapsed, setLapsed] = useState(null)
  const [isSmallNewsActive, setIsSmallNewsActive] = useState(false)
  const [smallNewsSlide, setSmallNewsSlide] = useState(0)
  const [smallNewsItemsCount, setSmallNewsItemsCount] = useState(0)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return function cleanup() {
      setMounted(false)
    }
  })

  useEffect(() => {
    setLapsed(props.lapsed)
  }, [props.lapsed])

  useEffect(() => {
    if (!mounted) return
    if (lapsed === null || lapsed === 0) return

    setTimeout(() => {
      setLapsed(lapsed + 1000)
    }, 1000)
  }, [lapsed])

  const sliderSettings = {
    adaptiveHeight: false,
    arrows: false,
    dots: false,
    infinite: false,
    draggable: true,
    swipe: true,
  }

  const getSmallNewsItems = () => {
    return props.gameData.smallNewsItems
      .filter((smallNewsItem) => {
        const timePassed = lapsed / 1000 > smallNewsItem.time
        const notRemoved = !props.gameProgress.removedSmallNewsItems.includes(smallNewsItem.id)
        const sameLoop = smallNewsItem.loop == props.gameProgress.loop
        return timePassed && notRemoved && sameLoop
      })
      .sort((itemA, itemB) => parseInt(itemA.time) - parseInt(itemB.time))
      .map((smallNewsItem) => smallNewsItem.id)
  }

  const getBigNewsItem = () => {
    if (!props.gameData || !props.gameData.bigNewsItems || !props.gameData.bigNewsItems.length) return false

    const bigNewsItems = props.gameData.bigNewsItems.filter((bigNewsItem) => {
      const timePassed = lapsed / 1000 > bigNewsItem.time
      const sameLoop = bigNewsItem.loop == props.gameProgress.loop
      return timePassed && sameLoop
    })

    const latestBigNewsItem = bigNewsItems.sort((itemA, itemB) => parseInt(itemB.time) - parseInt(itemA.time))
    return latestBigNewsItem.length ? latestBigNewsItem[0] : false
  }

  const bigNewsItem = getBigNewsItem()
  const smallNewsItems = getSmallNewsItems()

  const goToLastSlide = () => {
    if (!refSlider || !mounted) return

    refSlider.slickGoTo(smallNewsItems.length - 1)
  }

  const goToNextSlide = () => {
    if (!refSlider || !mounted) return

    refSlider.slickNext()
  }

  useEffect(() => {
    if (!smallNewsItems.length) return

    if (smallNewsItems.length > smallNewsItemsCount) {
      setSmallNewsSlide(true)
    }

    setSmallNewsItemsCount(smallNewsItems.length)
  }, [smallNewsItems.length])

  useEffect(() => {
    if (!smallNewsItemsCount || !smallNewsSlide) return

    goToLastSlide()
  }, [smallNewsItemsCount, smallNewsSlide])

  useEffect(() => {
    if (props.gameAction.actionState === 'ACTION_CLOSED') {
      goToLastSlide()
    }
  }, [props.gameAction.actionState])

  useEffect(() => {
    if (props.gameAction.actionState === 'ACTION_OPENED' || !smallNewsItems.length) {
      setIsSmallNewsActive(false)
    } else {
      setTimeout(props.showSmallNewsTutorial, 300)
      setIsSmallNewsActive(true)
    }
  }, [props.gameAction.actionState, smallNewsItems.length])

  const showBigNewsItem = bigNewsItem
  const showSmallNewsItems = props.gameAction.actionState === 'ACTION_CLOSED'

  useEffect(() => {
    if (isSmallNewsActive && showSmallNewsItems && smallNewsItems.length) {
      // change hotspot coordination
      const lastNewsId = smallNewsItems[smallNewsItems.length - 1]
      const lastNews = props.gameData.smallNewsItems.find((news) => news.id == lastNewsId)
      const x = lastNews.hotspotX || 50
      const y = lastNews.hotspotY || 50
      props.setHotspotCoordinate({ x, y })
    }
  }, [isSmallNewsActive, showSmallNewsItems, smallNewsItems.length])

  useEffect(() => {
    if (!showBigNewsItem) return

    setTimeout(props.showBigNewsTutorial, 700)
  }, [showBigNewsItem, bigNewsItem])

  return (
    <div>
      {showBigNewsItem && <BigNewsItem item={bigNewsItem} />}
      <div id="newswrapper" className={isSmallNewsActive && showSmallNewsItems ? '' : 'grid-inactive'}>
        <Slider className="news-grid" {...sliderSettings} ref={(slider) => setRefSlider(slider)}>
          {smallNewsItems.map((id) => (
            <SmallNewsItem
              key={id}
              text={props.gameData.smallNewsItems.find((news) => news.id == id).content}
              onClick={() => props.removeSmallNews(id)}
            />
          ))}
        </Slider>
      </div>
    </div>
  )

  return <BigNewsItem item={props.gameData.bigNewsItems[0]} />
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeSmallNews: (payload) => dispatch(removeSmallNews(payload)),
    showBigNewsTutorial: (payload) => dispatch(showBigNewsTutorial(payload)),
    setHotspotCoordinate: (payload) => dispatch(setHotspotCoordinate(payload)),
    showSmallNewsTutorial: (payload) => dispatch(showSmallNewsTutorial(payload)),
  }
}

const mapStateToProps = (state) => {
  const { gameAction, gameProgress } = state
  return { gameAction, gameProgress }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsItems)
