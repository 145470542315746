import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import configureStore from './redux/configure-store'
import { PersistGate } from 'redux-persist/integration/react'
import registerServiceWorker from './registerServiceWorker'

const { persistor, store } = configureStore()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementsByClassName('game-wrapper')[0]
);

reportWebVitals()
registerServiceWorker()
