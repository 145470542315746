import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  setLoading,
  setLastUpdate,
  setGameData,
  resetGameAction,
  resetGameProgress,
  resetTutorial,
  setGameId,
} from '../redux/actions'
import request from '../lib/request'
import PreloadWPMedia from '../components/PreloadWPMedia'
import helper from '../lib/helper'
import config from '../config'
import InstallStandaloneIOS from '../components/InstallStandaloneIOS'

function HomeScreen(props) {
  useEffect(() => {
    /*
      flow:
        try request last udpate
        if last update is same, then exit
        then else request new game data
    */

    const validate = () => {
      const alreadyHasContent = props.gameData.lastUpdate !== 0
      if (alreadyHasContent) return
      else return alert('Cannot connect to server, please try again')
    }

    request
      .getLastUpdate()
      .then((res) => {
        if (!res.ok) {
          return validate()
        }

        const lastUpdateTimestamp = parseInt(res.body.data)
        const newContent = props.gameData.lastUpdate !== lastUpdateTimestamp

        if (newContent) {
          request
            .getGameData()
            .then((res) => {
              if (!res.ok) return

              props.setLastUpdate(lastUpdateTimestamp)
              props.setGameData(res.body.data)
            })
            .catch(() => console.log('[WARNING] cannot get game data, use old one'))
        }
      })
      .catch(() => {
        console.log('[WARNING] cannot get last update, use old one')
        return validate()
      })
  }, [props.gameData.lastUpdate])

  const startGame = () => {
    props.setLoading(true)
    setTimeout(() => {
      props.resetGameAction()
      props.resetGameProgress()
      props.resetTutorial()
      props.history.push({ pathname: '/game' })
      props.setLoading(false)

      // send analytic
      const gameId = helper.generateGameId()
      request.postAnalytic(gameId, 'start')
      props.setGameId(gameId)
    }, 250)
  }

  const isLoading = props.gameData.lastUpdate === 0

  return (
    <div id="homeScreen-content">
      <div className="homescreen-container">
        <h3>De Kirmeense Kwestie</h3>
        {isLoading && <p>Laden...</p>}
        {!isLoading && (
          <p>
            Welkom bij de Kirmeense Kwestie, een game van het Ministerie van Defensie. Ben jij in staat om
            de-escalerend te handelen in een complex conflict?
          </p>
        )}
        <button className="btn btn-primary" onClick={startGame} disabled={isLoading ? 'disabled' : false}>
          Start de Game
        </button>
        {helper.showInstallMessageIOS() && <InstallStandaloneIOS />}
        <br />
        <br />

        <p className="appVersion">
          <span className="left">
            Een spel door{' '}
            <a href="https://frisseblikken.com/nl/proposities/game-studio">
              <img style={{ width: '100px' }} src="/assets/images/ff-logo.png" />
            </a>
          </span>
          <span className="right">Version {config.version}</span>
        </p>
        <PreloadWPMedia />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLastUpdate: (payload) => dispatch(setLastUpdate(payload)),
    setGameData: (payload) => dispatch(setGameData(payload)),
    resetGameAction: (payload) => dispatch(resetGameAction(payload)),
    resetGameProgress: (payload) => dispatch(resetGameProgress(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
    resetTutorial: (payload) => dispatch(resetTutorial(payload)),
    setGameId: (payload) => dispatch(setGameId(payload)),
  }
}

const mapStateToProps = (state) => {
  const { gameData } = state
  return { gameData }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen)
