const INITIAL_STATE = {
  lastUpdate: 0,
  data: {
    actionInsights: [],
    actionEnds: [],
    smallNewsItems: [],
    bigNewsItems: [],
    hints: [],
    endGame: [],
    config: {},
    questions: [],
  },
}

const gameDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_LAST_UPDATE':
      var { lastUpdate } = state
      lastUpdate = action.payload
      return { ...state, lastUpdate }
    case 'SET_GAME_DATA':
      var { data } = state
      data = action.payload
      return { ...state, data }
    default:
      return state
  }
}

export default gameDataReducer
