import React from 'react'
import './App.css'
import { BrowserRouter, Switch, Route, Link, Redirect } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen'
import LevelScreen from './screens/LevelScreen'
import EndScreen from './screens/EndScreen'
import Page404 from './screens/Page404'
import { connect } from 'react-redux'

window.onpopstate = function (event) {
  event.preventDefault()

  const path = window.location.pathname
  if (path.includes('game')) {
    window.history.go(1)

    setTimeout(() => {
      const goToHome = window.confirm(
        'Weet jet zeker dat je de game opnieuw wilt starten? Je voortgang wordt niet ogpeslagen.'
      )
      if (goToHome) {
        window.location.href = '/'
      }
    }, 500)
  } else {
    window.history.go(1)
  }
}

function App(props) {
  const bigNewsOpened = props.gameAction.showBigNews ? 'big-news-opened' : ''
  const isLoading = props.gameAction.isLoading ? 'screen-loading' : ''

  return (
    <div className={'defense-page ' + isLoading + ' ' + bigNewsOpened}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route path="/game" component={LevelScreen} />
          <Route path="/end" component={EndScreen} />
          <Route path="*" component={Page404} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { gameAction } = state
  return { gameAction }
}

export default connect(mapStateToProps, null)(App)
