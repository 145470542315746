const INITIAL_STATE = {
  seenTutorials: [],
  showSmallNewsTutorial: false,
  bigSmallNewsTutorial: false,
  insightActionOptionTutorial: false,
  insightActionSubmitTutorial: false,
  feedbackTutorial: false,
  showEndActionOptionTutorial: false,
  showHintButtonTutorial: false,
  showAfterShowHintTutorial: false,
  showEndActionSubmitTutorial: false,
  showInsightPointsTutorial: false,
  showTimerTutorial: false,
  showInsightActionButtonTutorial: false,
}

const tutorialReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_SEEN_TUTORIAL':
      var { seenTutorials } = state
      return {
        ...state,
        seenTutorials: state.seenTutorials.concat(action.payload),
      }
    case 'SHOW_SMALL_NEWS_TUTORIAL':
      var { showSmallNewsTutorial } = state
      showSmallNewsTutorial = true
      return { ...state, showSmallNewsTutorial }
    case 'SHOW_BIG_NEWS_TUTORIAL':
      var { showBigNewsTutorial } = state
      showBigNewsTutorial = true
      return { ...state, showBigNewsTutorial }
    case 'SHOW_INSIGHT_ACTION_OPTION_TUTORIAL':
      var { insightActionOptionTutorial } = state
      insightActionOptionTutorial = true
      return { ...state, insightActionOptionTutorial }
    case 'SHOW_INSIGHT_ACTION_SUBMIT_TUTORIAL':
      var { insightActionSubmitTutorial } = state
      insightActionSubmitTutorial = true
      return { ...state, insightActionSubmitTutorial }
    case 'SHOW_FEEDBACK_TUTORIAL':
      var { feedbackTutorial } = state
      feedbackTutorial = true
      return { ...state, feedbackTutorial }
    case 'SHOW_END_ACTION_OPTION_TUTORIAL':
      var { showEndActionOptionTutorial } = state
      showEndActionOptionTutorial = true
      return { ...state, showEndActionOptionTutorial }
    case 'SHOW_HINT_BUTTON_TUTORIAL':
      var { showHintButtonTutorial } = state
      showHintButtonTutorial = true
      return { ...state, showHintButtonTutorial }
    case 'SHOW_AFTER_SHOW_HINT_TUTORIAL':
      var { showAfterShowHintTutorial } = state
      showAfterShowHintTutorial = true
      return { ...state, showAfterShowHintTutorial }
    case 'SHOW_END_ACTION_SUBMIT_TUTORIAL':
      var { showEndActionSubmitTutorial } = state
      showEndActionSubmitTutorial = true
      return { ...state, showEndActionSubmitTutorial }
    case 'SHOW_INSIGHT_POINTS_TUTORIAL':
      var { showInsightPointsTutorial } = state
      showInsightPointsTutorial = true
      return { ...state, showInsightPointsTutorial }
    case 'SHOW_TIMER_TUTORIAL':
      var { showTimerTutorial } = state
      showTimerTutorial = true
      return { ...state, showTimerTutorial }
    case 'SHOW_INSIGHT_ACTION_BUTTON_TUTORIAL':
      var { showInsightActionButtonTutorial } = state
      showInsightActionButtonTutorial = true
      return { ...state, showInsightActionButtonTutorial }
    case 'RESET_TUTORIAL':
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default tutorialReducer
